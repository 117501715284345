import { useEffect } from "react";

const useRecaptcha = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LeQoZcpAAAAAMHIqWIU-uInU-uJ3HkRujd7X5zY";
    script.async = true;
    document.head.appendChild(script);
    return () => {
      script.remove();
    };
  }, []);

  const getToken = async () => {
    const token = await (
      window as unknown as Window & {
        grecaptcha: {
          execute: (
            token: string,
            { action }: { action: string }
          ) => Promise<string>;
        };
      }
    ).grecaptcha.execute("6LeQoZcpAAAAAMHIqWIU-uInU-uJ3HkRujd7X5zY", {
      action: "submit",
    });

    return token;
  };

  return { getToken };
};

export default useRecaptcha;
