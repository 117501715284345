import { Button, Flex, Heading, Input, Tag, Text } from "@lutains/ui";
import { CheckmarkFilled } from "@carbon/icons-react";

import TimSrc from "~/assets/landing-page/tim-2.png";
import LogoSrc from "~/assets/logo-black.png";
import usersSrc from "~/assets/users.png";

import "./Registration.css";
import { useFetcher } from "@remix-run/react";

export default function Registration({
  email,
  error,
  isLoading,
  onChange,
  onSubmit,
}: {
  email?: string;
  error?: string;
  isLoading: boolean;
  onChange: (e: string) => void;
  onSubmit: () => void;
}) {
  const fetcher = useFetcher();

  return (
    <Flex direction="column" gap={6}>
      <Flex justify="center">
        <img src={LogoSrc} alt="Logo" width={130} />
      </Flex>
      <Flex
        className="Bento Bento--black Registration"
        direction="column"
        // direction={{
        //   initial: "column",
        //   sm: "column",
        //   md: "column",
        //   lg: "row",
        // }}
        align="center"
        gap={6}
        justify="between"
        style={{ maxWidth: "860px" }}
      >
        <Flex direction="column" gap={6}>
          <Flex direction="column" align="center" gap={3}>
            <Tag color="yellow">
              <Text size={2} weight="medium">
                ⏰Lancement en janvier 2025
              </Text>
            </Tag>
            <Heading as="h1" color="white" size={8} className="text-center">
              Besoin d'aide pour ta <b className="highlight">landing page</b> ?
              Tim arrive !
            </Heading>
          </Flex>
          <Flex direction="column" gap={6} align="center">
            <Text color="white" size={5}>
              Inscris toi vite à la newsletter pour découvrir l’outil avant tout
              le monde ! Accède à des :
            </Text>
            <Flex
              gap={5}
              direction="column"
              justify="center"
              className="Registration__advantages"
            >
              <Flex>
                <Flex align="center" style={{ flex: 1 }} gap={2}>
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text color="white" size={3}>
                    Promos exclusives
                  </Text>
                </Flex>
                <Flex align="center" style={{ flex: 1 }} gap={2}>
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text size={3} color="white">
                    Accès privilégiés
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex align="center" style={{ flex: 1 }} gap={2}>
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text size={3} color="white">
                    Bonus inédits
                  </Text>
                </Flex>
                <Flex align="center" style={{ flex: 1 }} gap={2}>
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text size={3} color="white">
                    Bonus inédits
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <fetcher.Form>
              <Flex gap={4} pt={3} justify="center">
                <Input
                  placeholder="Entre ton adresse mail..."
                  type="email"
                  onChange={(e) => onChange(e as string)}
                  value={email}
                  className={error && "Input__input--error"}
                />
                <Button
                  variant="secondary"
                  onClick={onSubmit}
                  type="submit"
                  disabled={!email?.length}
                  loading={isLoading}
                >
                  Je m’inscris
                </Button>
              </Flex>
            </fetcher.Form>
            <Flex className="Home__container-error">
              {error && (
                <Flex>
                  {error === "errors.already_registered"
                    ? `Il semblerait que tu sois déjà inscrit(e) !`
                    : `Oups, une erreur s’est produite, les lutains sont sur le coup !`}
                </Flex>
              )}
            </Flex>
            <Flex justify="center" pt={3} gap={2} align="center">
              <img src={usersSrc} alt="Users" width={60} />
              <Text color="white" size={2}>
                Rejoins + de 100 inscrits !
              </Text>
            </Flex>
          </Flex>
          <img src={TimSrc} alt="Tim" className="Registration__tim" />
        </Flex>
      </Flex>
    </Flex>
  );
}
